import { template as template_65e0147a8576407881a6eeeac048b47f } from "@ember/template-compiler";
const WelcomeHeader = template_65e0147a8576407881a6eeeac048b47f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
