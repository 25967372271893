import { template as template_e8da9acd98084bf28b3ed191cece4349 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e8da9acd98084bf28b3ed191cece4349(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
