import { template as template_84713808ec22401db656f9e4acab384a } from "@ember/template-compiler";
const FKControlMenuContainer = template_84713808ec22401db656f9e4acab384a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
