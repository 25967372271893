import { template as template_6d03672d47234f2188aa7a08e54790cc } from "@ember/template-compiler";
const FKLabel = template_6d03672d47234f2188aa7a08e54790cc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
