import { template as template_be2568f257154777906fe9691ab51edf } from "@ember/template-compiler";
const SidebarSectionMessage = template_be2568f257154777906fe9691ab51edf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
